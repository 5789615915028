import * as yup from 'yup';

const ruleSchema = yup.object({
  name: yup
    .string()
    .min(4, 'Минимальная длина названия 4')
    .max(200, 'Очень длинное название')
    .required('Обязательное поле'),
  description: yup.string().min(10, 'Минимальная длина описания 10').required('Обязательное поле'),
  priority: yup.string().nullable().required('Выберите одно'),
  status: yup.string().nullable().required('Выберите одно'),
  positions: yup.array().of(
    yup.object().shape({
      items: yup.array().of(
        yup.object().shape({
          id: yup.number(),
          type: yup.number(),
        }),
      ).min(1, 'Добавьте сервис или группу сервисов').required(),
    }),
  ).min(1, 'Обязательно добавьте позицию').required(),
  locations: yup.array().of(
    yup.object().shape({
      ids: yup.array().of(yup.number().required('Добавьте местоположение')),
      type: yup.number(),
    }),
  ),
  checkSubAgents: yup.boolean().required(),
});

export default ruleSchema;
