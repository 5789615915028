import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import AuthLayout from 'views/layouts/AuthLayout';
import { checkAuth } from 'store/slices/authSlice';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';

interface IGuestRouteProps {
  children: React.ReactNode,
}

const GuestRoute = ({ children }: IGuestRouteProps) => {
  const dispatch = useAppDispatch();

  const { isAuth } = useAppSelector((state) => state.auth);

  const location = useLocation();
  const url = new URLSearchParams(location.search.slice(1));

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  return (
    <AuthLayout>
      {isAuth ? <Navigate to={url.get('redirect') || '/'} /> : children}
    </AuthLayout>
  );
};

export default GuestRoute;
