import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Error from 'views/Error';
import Login from 'views/Login';
import Rules from 'views/Rules';
import Users from 'views/Users';
import EditRule from 'views/Rules/EditRule/EditRule';
import UsersList from 'views/Users/UsersList/UsersList';
import RulesList from 'views/Rules/RulesList/RulesList';
import AddNewRule from 'views/Rules/AddNewRule/AddNewRule';

import GuestRoute from './GuestRoute';
import PrivateRoute from './PrivateRoute';

const routes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PrivateRoute><Navigate to="/rules" replace /></PrivateRoute>} />
      <Route path="login" element={<GuestRoute><Login /></GuestRoute>} />
      <Route path="rules" element={<PrivateRoute><Rules /></PrivateRoute>}>
        <Route index element={<RulesList />} />
        <Route path="add" element={<AddNewRule />} />
        <Route path=":id" element={<EditRule />} />
      </Route>
      <Route path="users" element={<PrivateRoute><Users /></PrivateRoute>}>
        <Route index element={<UsersList />} />
      </Route>
      <Route path="*" element={<PrivateRoute><Error /></PrivateRoute>} />
    </Routes>
  </BrowserRouter>
);

export default routes;
