import { IResponse } from 'types/response';
import { IInitialServices } from 'types/services';

import axiosInstance from '../axios';

const services = {
  getAllServices: () => axiosInstance.get<IResponse<IInitialServices>>('/services'),
  getAllGroups: () => axiosInstance.get<IResponse<IInitialServices>>('/groups'),
};

export default services;
