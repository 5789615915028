/* eslint-disable no-underscore-dangle */
import { IUser } from 'types/users';
import { ISearchParams } from 'types/searchParams';
import { IResponseWithPagination } from 'types/response';

import axiosInstance from '../axios';

const users = {
  getUsersByQueryParams: (queryParams: ISearchParams) => axiosInstance
    .get<IResponseWithPagination<IUser[]>>('/users', { params: queryParams }),
};

export default users;
