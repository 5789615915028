import auth from './endpoints/auth';
import users from './endpoints/users';
import rules from './endpoints/rules';
import services from './endpoints/services';
import locations from './endpoints/locations';

const allEndPoints = {
  auth,
  users,
  rules,
  services,
  locations,
};

export default allEndPoints;
