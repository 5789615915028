import React from 'react';

import Header from './Header/Header';
import BreadCrumbs from './BreadCrumbs/BreadCrumbs';
import FlashMessages from './FlashMessages/FlashMessages';
import s from './userLayout.module.css';

interface IUserLayoutProps {
  children: React.ReactNode,
}

const UserLayout = ({ children }: IUserLayoutProps) => (
  <>
    <Header />
    <div className={s.wrapper}>
      <BreadCrumbs />
      {children}
    </div>
    <FlashMessages />
  </>
);

export default UserLayout;
