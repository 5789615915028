import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import { FaUserCircle as ProfileIcon } from 'react-icons/fa';
import { BiChevronDown as DropdownIcon } from 'react-icons/bi';

import logo from 'assets/svgs/Kassa24.svg';
import { HEADER_NAVS } from 'constants/app';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useAppSelector } from 'hooks/typedReduxHooks';

import s from './Header.module.css';
import ProfileCard from './ProfileCard/ProfileCard';

const Header = () => {
  const [isOpenProfile, setOpenProfile] = useState(false);
  const login = useAppSelector((state) => state.auth.profile?.login_ad);

  const headerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(() => setOpenProfile(false), headerRef);

  return (
    <div className={s.wrapper} ref={headerRef}>
      <header>
        <img src={logo} alt="logo" />
        <nav className={s.nav}>
          {HEADER_NAVS.map(({ to, name, icon: Icon }) => (
            <NavLink
              to={to}
              className={(navData) => (navData.isActive ? s.active : null)}
              key={name}
            >
              <Icon />
              {name}
            </NavLink>
          ))}
        </nav>
        <button
          type="button"
          className={s.profileButton}
          onClick={() => setOpenProfile(!isOpenProfile)}
        >
          <ProfileIcon />
          {login}
          <DropdownIcon className={classNames(s.dropdownIcon, isOpenProfile && s.rotate)} />
        </button>
      </header>
      {isOpenProfile && <div className={s.profileWrapper}><ProfileCard /></div>}
    </div>
  );
};

export default Header;
