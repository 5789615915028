import React, { useEffect, useState } from 'react';

import s from './Tooltip.module.css';

interface IProps {
  children: React.ReactNode,
  className?: any,
  tooltip?: string | null,
}

const Tooltip: React.FC<IProps> = ({ children, tooltip, className }) => {
  const [isShownTooltip, setShownTooltip] = useState(false);

  const showTooltip = () => setShownTooltip(true);
  const hideTooltip = () => setShownTooltip(false);

  useEffect(() => {
    document.addEventListener('scroll', hideTooltip);

    return () => {
      document.removeEventListener('scroll', hideTooltip);
    };
  }, []);

  return (
    <>
      <div
        className={className}
        onMouseOver={showTooltip}
        onFocus={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      {isShownTooltip && tooltip && (
        <div className={s.tooltip}>
          {tooltip}
        </div>
      )}
    </>
  );
};

export default Tooltip;
