import React from 'react';
import classNames from 'classnames';
import { GoPlus as Plus } from 'react-icons/go';
import { FaRegTrashAlt as Trash } from 'react-icons/fa';

import s from './SquareButton.module.css';

interface ISquareButtonProps{
  purpose: 'add' | 'remove',
  onClick: () => void,
  disabled?: boolean,
}

const SquareButton = ({ purpose, onClick, disabled }: ISquareButtonProps) => (
  <button
    className={classNames(s.button, s[purpose])}
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    { purpose === 'add' && <Plus /> }
    { purpose === 'remove' && <Trash /> }
  </button>
);

export default SquareButton;
