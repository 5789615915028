import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'hooks/typedReduxHooks';
import { RULES_FILTERS as filters } from 'constants/rules';
import { setMultiParamSelector } from 'utils/multiParamSelector';

import Sort from './Sort/Sort';
import Filter from './Filter/Filter';
import s from './Controls.module.css';
import ParamButton from './ParamButton/ParamButton';

const Controls = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { profile } = useAppSelector((state) => state.auth);

  const clearFiltersFromSearchParams = () => {
    filters.forEach(({ name, options }) => {
      let filterOptions = options;
      if (name === 'priority' && profile?.role) {
        filterOptions = options[profile?.role];
      }
      const isMulti = filterOptions.length > 2;
      const searchParamName = isMulti ? setMultiParamSelector(name) : name;
      searchParams.delete(searchParamName);
    });
    searchParams.delete('login_ad');

    setSearchParams(searchParams);
  };

  return (
    <div className={s.container}>
      <Sort />
      {filters.map(({ options, name, placeholder }) => (
        <Filter
          options={(name === 'priority' && profile?.role) ? options[profile?.role] : options}
          name={name}
          placeholder={placeholder}
          key={name}
        />
      ))}
      {profile?.login_ad && (
        <ParamButton
          paramName="login_ad"
          paramValue={profile.login_ad}
          text={['Только мои', 'Все']}
        />
      )}
      <button
        className={s.clearFilters}
        type="button"
        onClick={clearFiltersFromSearchParams}
      >
        Очистить фильтры
      </button>
    </div>
  );
};

export default Controls;
