import React from 'react';

import s from './Loading.module.css';

interface ILoadingProps{
  position: 'center',
}

const Loading = ({ position }: ILoadingProps) => (
  <div className={s[position]}>
    <div className={s.ldsRing}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loading;
