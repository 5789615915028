import { configureStore } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import rulesSlice from './slices/rulesSlice';
import usersSlice from './slices/usersSlice';
import modalSlice from './slices/modalSlice';
import locationsSlice from './slices/locationsSlice';
import positionsSlice from './slices/positionsSlice';
import notificationSlice from './slices/notificationSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    rules: rulesSlice,
    users: usersSlice,
    modal: modalSlice,
    locations: locationsSlice,
    positions: positionsSlice,
    notification: notificationSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;
