import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { INewRule, IRule } from 'types/rules';
import ruleSchema from 'yupSchemas/ruleSchema';
import { ErrorMessage } from 'components/feedBacks';
import { useAppDispatch } from 'hooks/typedReduxHooks';
import { ContainedButton, OutlinedButton } from 'components/buttons';
import { fetchAllGroups, fetchAllServices } from 'store/slices/positionsSlice';
import {
  RULES_PRIORITY,
  RULE_STATUS,
  RULE_STATUS_LABELS,
  RULE_STATUS_COLORS,
  RULES_PRIORITY_ALL_LIST,
  LOCATION_TYPES,
} from 'constants/rules';
import {
  Radio,
  Switch,
  DateTime,
  TextField,
  RadioGroup,
} from 'components/forms';
import {
  fetchAllAgents,
  fetchAllRegions,
  fetchAllTerminals,
} from 'store/slices/locationsSlice';

import { useSelector } from 'react-redux';
import useUser from 'hooks/useUser';
import s from './RuleForm.module.css';
import LocationsSection from './LocationsSection/LocationsSection';
import PositionsSection from './PositionsSection/PositionsSection';

interface IRuleFormProps {
  title: string,
  initialValues?: IRule,
  onSubmit: (rule: IRule | INewRule) => void,
  submitButtonName: string,
}

const RuleForm = ({
  title,
  initialValues,
  onSubmit,
  submitButtonName,
}: IRuleFormProps) => {
  const { isAdmin } = useUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profile } = useSelector((state: any) => state.auth);

  useEffect(() => {
    dispatch(fetchAllAgents());
    dispatch(fetchAllRegions());
    dispatch(fetchAllTerminals());
    dispatch(fetchAllGroups());
    dispatch(fetchAllServices());
  }, [dispatch]);

  const defaultValues: INewRule = {
    name: '',
    description: '',
    time_begin: undefined,
    time_end: undefined,
    status: RULE_STATUS.ACTIVE,
    priority: isAdmin ? RULES_PRIORITY_ALL_LIST.NOT_VERY_LOW : RULES_PRIORITY_ALL_LIST.MEDIUM,
    positions: [],
    locations: [],
    checkSubAgents: false,
  };

  const {
    errors,
    values,
    touched,
    resetForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues || defaultValues,
    validationSchema: ruleSchema,
    onSubmit: (rule) => {
      const positionsWithoutEmpty = rule.positions?.filter((position) => !!position);
      const locationsWithoutEmpty = rule.locations?.filter((location) => !!location).map(({ type, ids, lock }) => ({
        type: type === LOCATION_TYPES.IGNORE_TERMINALS ? LOCATION_TYPES.TERMINALS : type,
        ids,
        lock,
      }));

      const correctedRule = {
        ...rule,
        priority: Number(rule.priority),
        status: Number(rule.status),
        time_begin: rule.time_begin || null,
        time_end: rule.time_end || null,
        positions: positionsWithoutEmpty,
        locations: locationsWithoutEmpty,
      };
      onSubmit(correctedRule as IRule);
    },
  });

  return (
    <>
      <div className={s.headLine}>
        <h1 className={s.title}>{title}</h1>
        {!initialValues && (
        <a
          className={s.link}
          target="_blank"
          href="https://drive.google.com/file/d/1ysskukx5rlJ47FSrl8CcAEwlA14bmoFV/view"
          rel="noreferrer"
        >
          Видеоинструкция по созданию правила
        </a>
        )}
      </div>
      <div className={s.container}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className={s.firstBlock}>
            <TextField
              type="text"
              id="name"
              label="Введите название правила"
              labelOnFocus="Название правила"
              value={values.name}
              onChange={handleChange}
              isError={!!(errors.name && touched.name)}
            />
            {(touched.name && errors.name) && <ErrorMessage>{errors.name}</ErrorMessage>}
            <TextField
              multiline
              id="description"
              label="Введите описание правила"
              labelOnFocus="Описание правила"
              value={values.description}
              onChange={handleChange}
              isError={!!(errors.description && touched.description)}
            />
            {(touched.description && errors.description) && <ErrorMessage>{errors.description}</ErrorMessage>}
            <div className={s.dateTimes}>
              <DateTime
                label="Выберите дату начала"
                labelOnFocus="Дата начала"
                id="time_begin"
                name="time_begin"
                value={values.time_begin}
                onChange={setFieldValue}
              />
              <DateTime
                label="Выберите дату окончания"
                labelOnFocus="Дата окончания"
                id="time_end"
                name="time_end"
                value={values.time_end}
                onChange={setFieldValue}
              />
            </div>

            <RadioGroup title="Укажите приоритет">
              {RULES_PRIORITY[profile?.role]?.map((priority:any) => (
                <Radio
                  label={priority.label}
                  id={priority.id}
                  name="priority"
                  value={priority.value}
                  color={priority.color}
                  checked={Number(values.priority) === priority.value}
                  onChange={handleChange}
                  key={priority.id}
                />
              ))}
            </RadioGroup>
            {(touched.priority && errors.priority) && <ErrorMessage>{errors.priority}</ErrorMessage>}
            <RadioGroup title="Статус">
              <Radio
                label={RULE_STATUS_LABELS[RULE_STATUS.ACTIVE]}
                id="activeStatus"
                name="status"
                value={RULE_STATUS.ACTIVE}
                color={RULE_STATUS_COLORS[RULE_STATUS.ACTIVE]}
                checked={(values.status && +values.status) === RULE_STATUS.ACTIVE}
                onChange={handleChange}
              />
              <Radio
                label={RULE_STATUS_LABELS[RULE_STATUS.BLOCKED]}
                id="blockedStatus"
                name="status"
                value={RULE_STATUS.BLOCKED}
                color={RULE_STATUS_COLORS[RULE_STATUS.BLOCKED]}
                checked={(values.status && +values.status) === RULE_STATUS.BLOCKED}
                onChange={handleChange}
              />
            </RadioGroup>
            {(touched.status && errors.status) && <ErrorMessage>{errors.status}</ErrorMessage>}
          </div>
          <hr />
          <div className={s.secondBlock}>
            <PositionsSection
              errors={errors.positions}
              touched={touched.positions}
              name="positions"
              positions={values.positions}
              setFieldValue={setFieldValue}
            />
            <LocationsSection
              name="locations"
              locations={values.locations}
              setFieldValue={setFieldValue}
            />
            <Switch
              label="Учитывать субагентов"
              id="checkSubAgents"
              onChange={handleChange}
              checked={values.checkSubAgents}
            />
          </div>
          <div className={s.buttons}>
            <OutlinedButton
              type="button"
              onClick={() => {
                resetForm();
                navigate('/rules');
              }}
            >
              Отмена
            </OutlinedButton>
            <ContainedButton type="submit">
              {submitButtonName}
            </ContainedButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default RuleForm;
