import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { INewRule, IRule } from 'types/rules';
import { Loading } from 'components/feedBacks';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';
import { fetchRuleById, editRule, clearRuleById } from 'store/slices/rulesSlice';

import RuleForm from '../RuleForm/RuleForm';

const EditRule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ruleById, status } = useAppSelector((state) => state.rules);
  const { id } = useParams();

  useEffect(() => {
    id && dispatch(fetchRuleById(id));

    return () => {
      dispatch(clearRuleById());
    };
  }, [dispatch, id]);

  const onSubmit = (editedRule: IRule | INewRule) => {
    id && dispatch(editRule({ editedRule, id }))
      .unwrap()
      .then(() => navigate('/rules'));
  };

  return (ruleById && (
    <RuleForm
      title="Редактирование правила"
      initialValues={ruleById}
      onSubmit={onSubmit}
      submitButtonName="Редактировать"
    />
  )) || (status === 'loading' && (
    <Loading position="center" />
  )) || null;
};

export default EditRule;
