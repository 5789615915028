import { useMemo } from 'react';
import { USER_ROLES } from 'constants/rules';
import { useAppSelector } from './typedReduxHooks';

const useUser = () => {
  const { profile } = useAppSelector((state) => state.auth);
  const isAdmin = useMemo(() => profile?.role === USER_ROLES.ADMIN, [profile?.role]);
  const isManager = useMemo(() => profile?.role === USER_ROLES.MANAGER, [profile?.role]);

  return {
    profile,
    isManager,
    isAdmin,
  };
};

export default useUser;
