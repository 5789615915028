/* eslint import/no-unresolved: 0 */
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import store from 'store';
import Routes from 'routes';

import './index.css';

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes />
    </Provider>
  </React.StrictMode>,
);
