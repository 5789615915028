import React from 'react';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  paramName: string;
  paramValue: string;
  text: [string, string];
}

const ParamButton = ({ paramName, paramValue, text }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const buttonText = searchParams.get(paramName) ? text[1] : text[0];

  const onClickParamButton = () => {
    const isExistParam = !!searchParams.get(paramName);

    if (isExistParam) {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, paramValue);
    }

    setSearchParams(searchParams);
  };

  return (
    <button
      className="bg-white rounded-sm shadow-md text-gray-69 text-sm px-[14px]"
      type="button"
      onClick={onClickParamButton}
    >
      {buttonText}
    </button>
  );
};

export default ParamButton;
