import classNames from 'classnames';
import React, { useState } from 'react';
import { HiEyeOff as EyeOff, HiEye as Eye } from 'react-icons/hi';

import s from './PasswordField.module.css';

interface IPasswordField {
  label:string,
  labelOnFocus: string,
  id: string,
  value: string | undefined,
  onChange: (e: React.ChangeEvent) => void,
  isError: boolean,
}

const PasswordField = ({
  label,
  labelOnFocus,
  id,
  value,
  onChange,
  isError,
}: IPasswordField) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const editorClassName = (className: string | undefined) => (isError ? classNames(className, s.error) : className);

  return (
    <div className={s.container}>
      <input
        className={editorClassName(s.input)}
        type={showPassword ? 'text' : 'password'}
        placeholder={label}
        id={id}
        value={value}
        onChange={onChange}
      />
      <label className={editorClassName(s.borderLabel)} htmlFor={id}>
        {labelOnFocus}
      </label>
      <button type="button" className={editorClassName(s.icon)} onClick={handleClick}>
        {showPassword ? <Eye /> : <EyeOff />}
      </button>
    </div>
  );
};

export default PasswordField;
