import { useEffect, useState } from 'react';

const useSearch = (data: string[] | undefined): [typeof searchedData, typeof search, typeof setSearch] => {
  const [search, setSearch] = useState<string>();
  const [searchedData, setSearchedData] = useState(data);

  useEffect(() => {
    let result = data && [...data];
    if (search) {
      result = result && result.filter(
        (item) => item.toLowerCase().includes(search.toLowerCase()),
      );
    }
    setSearchedData(result);
  }, [search, data]);

  return [
    searchedData,
    search,
    setSearch,
  ];
};

export default useSearch;
