import React from 'react';
import { useNavigate } from 'react-router-dom';

import errorImage from 'assets/svgs/Error404.svg';
import { ContainedButton } from 'components/buttons';

import s from './error.module.css';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className={s.container}>
      <p>Что-то пошло не так, но мы работаем над устранением проблемы</p>
      <img src={errorImage} alt="Error 404" />
      <div className={s.buttonWrapper}>
        <ContainedButton type="button" onClick={() => navigate('/rules')}>
          Вернуться на главную
        </ContainedButton>
      </div>
    </div>
  );
};

export default ErrorPage;
