import React from 'react';

import s from './Switch.module.css';

interface ISwitchProps {
  label: string,
  id: string,
  checked?: boolean,
  onChange: (e?: React.ChangeEvent) => void,
}

const Switch = ({
  label,
  id,
  checked,
  onChange,
}: ISwitchProps) => (
  <div className={s.container}>
    <input
      className={s.check}
      type="checkbox"
      id={id}
      checked={checked}
      onChange={onChange}
    />
    <label className={s.borderCover} htmlFor={id}>
      <span className={s.slider}>{' '}</span>
    </label>
    <span className={s.label}>{label}</span>
  </div>
);

export default Switch;
