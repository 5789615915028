import React, { useEffect } from 'react';
import classNames from 'classnames';
import { BsInfoCircle as InfoIcon } from 'react-icons/bs';
import {
  IoWarningOutline as WarningIcon,
  IoAlertCircleOutline as ErrorIcon,
  IoCheckmarkCircleOutline as SuccessIcon,
} from 'react-icons/io5';

import { TIME_TO_SHOW } from 'constants/notifications';
import { useAppDispatch } from 'hooks/typedReduxHooks';
import { hideNotification } from 'store/slices/notificationSlice';

import s from './FlashMessage.module.css';

interface IFlashMessageProps{
  type: 'error' | 'info' | 'success' | 'warning',
  children: string,
  id: string,
}

const FlashMessage = ({ type, children: message, id }: IFlashMessageProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideNotification(id));
    }, TIME_TO_SHOW);
  }, [dispatch, id]);

  const icons = {
    info: <InfoIcon />,
    error: <ErrorIcon />,
    success: <SuccessIcon />,
    warning: <WarningIcon />,
  };

  return (
    <div className={classNames(s.alert, s[type])}>
      <span className={s.icon}>{icons[type]}</span>
      {message}
    </div>
  );
};

export default FlashMessage;
