import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'store/slices/modalSlice';

import s from './Modal.module.css';

interface IModalProps {
  id: string,
  children: React.ReactNode,
}

const Modal = ({ id, children }: IModalProps) => {
  const { activeModals } = useSelector((state: any) => state.modal);
  const dispatch = useDispatch();

  if (activeModals.includes(id)) {
    return (
      <div
        className={s.overlay}
        onClick={() => dispatch(closeModal(id))}
        onKeyPress={() => dispatch(closeModal(id))}
        role="button"
        tabIndex={0}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
          role="button"
          tabIndex={0}
        >
          {children}
        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
