const USER_ROLE = {
  admin: 'Админ',
  manager: 'Менеджер',
};

const USERS_PER_PAGE = 7;

export {
  USER_ROLE,
  USERS_PER_PAGE,
};
