/* eslint import/no-unresolved: 0 */
import flatpickr from 'flatpickr';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import React, { useCallback, useRef } from 'react';
import { RiCalendar2Fill as CalendarIcon, RiCloseCircleFill as ClearIcon } from 'react-icons/ri';

import s from './DateTime.module.css';

interface IDateTimeProps {
  label: string,
  labelOnFocus: string,
  id?: string,
  value?: string | Date,
  name: string,
  onChange: (name: string, dateObj: Date | undefined) => void,
}

const DatePicker: any = Flatpickr;

const DateTime = ({
  label,
  labelOnFocus,
  id,
  value,
  name,
  onChange,
}: IDateTimeProps) => {
  const flatpickrRef = useRef<Flatpickr>(null);

  const handleClick = useCallback(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.input.focus();
      flatpickrRef.current.flatpickr.open();
    }
  }, []);

  const handleClear = useCallback(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
  }, []);

  return (
    <div className={s.container}>
      <DatePicker
        className={s.input}
        id={id}
        value={value}
        ref={flatpickrRef}
        defaultValue="today"
        placeholder=" "
        data-enable-time
        options={{
          time_24hr: true,
          locale: Russian,
          dateFormat: 'd.m.Y H:i',
          onChange: (_: any, dateStr: string) => {
            const dateObj = flatpickr.parseDate(dateStr, 'd.m.Y H:i');

            return onChange(name, dateObj);
          },
        }}
      />
      <label className={s.label} htmlFor="date">
        <span>{label}</span>
      </label>
      <label className={s.borderLabel} htmlFor="date">
        <span>{labelOnFocus}</span>
      </label>
      {value ? (
        <button className={s.icon} type="button" onClick={handleClear}>
          <ClearIcon />
        </button>
      ) : (
        <button className={s.icon} type="button" onClick={handleClick}>
          <CalendarIcon />
        </button>
      )}
    </div>
  );
};

export default DateTime;
