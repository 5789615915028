import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';
import { BsDot as Dot } from 'react-icons/bs';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import breadcrumbRoutes from 'constants/breadcrumbRoutes';

import s from './BreadCrumbs.module.css';

const BreadCrumbs = () => {
  const breadcrumbs = useBreadcrumbs(breadcrumbRoutes);
  const isErrorPage = breadcrumbs.some(({ match }) => !match.route);

  return !isErrorPage ? (
    <ul className={s.container}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        const isLast = index === breadcrumbs.length - 1;

        const key = uuid();

        return isLast ? (
          <li className={s.last} key={key}>{breadcrumb}</li>
        ) : (
          <Fragment key={key}>
            <li className={s.item}>
              <Link to={match.pathname}>{breadcrumb}</Link>
            </li>
            <Dot className={s.dot} />
          </Fragment>
        );
      })}
    </ul>
  ) : null;
};

export default BreadCrumbs;
