import React, { ReactElement } from 'react';

import s from './ButtonGroup.module.css';

interface IButtonGroupProps {
  children?: React.ButtonHTMLAttributes<HTMLButtonElement>[],
}

const ButtonGroup = ({ children: buttonGroup }: IButtonGroupProps) => (
  <div className={s.wrapper}>
    {buttonGroup as ReactElement[]}
  </div>
);

export default ButtonGroup;
