import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch } from 'hooks/typedReduxHooks';
import { fetchRulesByParams } from 'store/slices/rulesSlice';
import useCustomSearchParams from 'hooks/useCustomSearchParams';

const Rules = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useCustomSearchParams();

  useEffect(() => {
    dispatch(fetchRulesByParams(searchParams));
  }, [dispatch, searchParams]);

  return <Outlet />;
};

export default Rules;
