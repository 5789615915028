import { IResponse } from 'types/response';
import { IInitialLocations } from 'types/locations';

import axiosInstance from '../axios';

const locations = {
  getAllAgents: () => axiosInstance.get<IResponse<IInitialLocations>>('/agents'),
  getAllRegions: () => axiosInstance.get<IResponse<IInitialLocations>>('/regions'),
  getAllTerminals: () => axiosInstance.get<IResponse<IInitialLocations>>('/terminals'),
};

export default locations;
