import { IRulesFilter } from 'types/rules';

const USER_ROLES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
};

const LOCATION_TYPES = {
  AGENTS: 1,
  REGIONS: 2,
  TERMINALS: 3,
  IGNORE_TERMINALS: 4,
} as const;

const SERVICE_TYPES = {
  SERVICES: 1,
  SERVICES_GROUPS: 2,
} as const;

export const RULE_STATUS = {
  ACTIVE: 1,
  BLOCKED: 2,
};

const RULE_STATUS_LABELS = {
  [RULE_STATUS.ACTIVE]: 'Активен',
  [RULE_STATUS.BLOCKED]: 'Заблокирован',
};

const RULE_STATUS_COLORS = {
  [RULE_STATUS.ACTIVE]: 'green',
  [RULE_STATUS.BLOCKED]: 'red',
} as const;

export const RULES_PRIORITY_ALL_LIST = {
  LOWEST: 1,
  MEDIUM: 2,
  NOT_VERY_LOW: 3,
  NOT_VERY_HIGH: 4,
  HIGH: 5,
  HIGHEST: 6,
};

const RULES_PRIORITY:any = {
  [USER_ROLES.MANAGER]: [
    {
      id: 'lowPriority',
      color: 'green',
      label: 'Низкий приоритет',
      value: RULES_PRIORITY_ALL_LIST.LOWEST,
    },
    {
      id: 'mediumPriority',
      color: 'yellow',
      label: 'Средний приоритет',
      value: RULES_PRIORITY_ALL_LIST.MEDIUM,
    },
    {
      id: 'highPriority',
      color: 'red',
      label: 'Высокий приоритет',
      value: RULES_PRIORITY_ALL_LIST.NOT_VERY_LOW,
    },
  ],
  [USER_ROLES.ADMIN]: [
    {
      id: 'veryLowPriority',
      color: 'lightGreen',
      label: 'Незначительный приоритет',
      value: RULES_PRIORITY_ALL_LIST.LOWEST,
    },
    {
      id: 'lowPriority',
      color: 'green',
      label: 'Низкий приоритет',
      value: RULES_PRIORITY_ALL_LIST.MEDIUM,
    },
    {
      id: 'mediumPriority',
      color: 'orange',
      label: 'Средний приоритет',
      value: RULES_PRIORITY_ALL_LIST.NOT_VERY_LOW,
    },
    {
      id: 'highPriority',
      color: 'yellow',
      label: 'Высокий приоритет',
      value: RULES_PRIORITY_ALL_LIST.NOT_VERY_HIGH,
    },
    {
      id: 'veryHighPriority',
      color: 'lightRed',
      label: 'Очень высокий приоритет',
      value: RULES_PRIORITY_ALL_LIST.HIGH,
    },
    {
      id: 'veryVeryHighPriority',
      color: 'red',
      label: 'Наивысший приоритет',
      value: RULES_PRIORITY_ALL_LIST.HIGHEST,
    },
  ],
};

const MIN_COUNT_POSITIONS = 1;
const MAX_COUNT_POSITIONS = 12;
const DEFAULT_COUNT_POSITIONS = 10;

const RULES_PER_PAGE = 7;

const RULES_SORT_PARAMS = [
  {
    value: 'priority',
    label: 'По приоритету',
  },
  {
    value: 'time_end',
    label: 'По дате окончания',
  },
  {
    value: 'time_begin',
    label: 'По дате начала',
  },
  {
    value: 'name',
    label: 'По названию',
  },
];

const RULES_FILTERS: IRulesFilter[] = [
  {
    name: 'status',
    placeholder: 'Статус',
    options: [
      {
        value: RULE_STATUS.ACTIVE.toString(),
        label: RULE_STATUS_LABELS[RULE_STATUS.ACTIVE],
      },
      {
        value: RULE_STATUS.BLOCKED.toString(),
        label: RULE_STATUS_LABELS[RULE_STATUS.BLOCKED],
      },
    ],
  },
  {
    name: 'priority',
    placeholder: 'Приоритет',
    options: {
      [USER_ROLES.MANAGER]: [
        {
          value: RULES_PRIORITY_ALL_LIST.LOWEST,
          label: 'Низкий',
        },
        {
          value: RULES_PRIORITY_ALL_LIST.MEDIUM,
          label: 'Средний',
        },
        {
          value: RULES_PRIORITY_ALL_LIST.NOT_VERY_LOW,
          label: 'Высокий',
        },
      ],
      [USER_ROLES.ADMIN]: [{
        value: RULES_PRIORITY_ALL_LIST.LOWEST,
        label: 'Незначительный',
      },
      {
        value: RULES_PRIORITY_ALL_LIST.MEDIUM,
        label: 'Низкий',
      },
      {
        value: RULES_PRIORITY_ALL_LIST.NOT_VERY_LOW,
        label: 'Средний',
      }, {
        value: RULES_PRIORITY_ALL_LIST.NOT_VERY_HIGH,
        label: 'Высокий',
      }, {
        value: RULES_PRIORITY_ALL_LIST.HIGH,
        label: 'Очень высокий',
      }, {
        value: RULES_PRIORITY_ALL_LIST.HIGHEST,
        label: 'Наивысший',
      }],
    },
  },
];

export {
  USER_ROLES,
  RULES_PRIORITY,
  RULES_FILTERS,
  SERVICE_TYPES,
  LOCATION_TYPES,
  RULES_PER_PAGE,
  RULES_SORT_PARAMS,
  RULE_STATUS_LABELS,
  RULE_STATUS_COLORS,
  MAX_COUNT_POSITIONS,
  MIN_COUNT_POSITIONS,
  DEFAULT_COUNT_POSITIONS,
};
