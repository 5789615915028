import React from 'react';
import { FixedSizeList } from 'react-window';
import { GroupBase, MenuListProps } from 'react-select';

import { IOption } from 'types/shared';

const List: any = FixedSizeList;

const MenuList = ({
  maxHeight,
  children,
  innerRef,
  focusedOption,
  innerProps,
}: MenuListProps<IOption, boolean, GroupBase<IOption>>) => {
  const itemHeight = 35;

  return (
    <div>
      <List
        height={350}
        itemCount={(children as React.ReactNode[])?.length}
        itemSize={itemHeight}
      >
        {({ index, style }: any) => (
          <div
            className="px-[8px] py-[3px]"
            style={style}
          >
            {(children as React.ReactNode[])[index]}
          </div>
        )}
      </List>
    </div>
  );
};

export default MenuList;
