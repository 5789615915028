import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch } from 'hooks/typedReduxHooks';
import { fetchUsersBySearchParams } from 'store/slices/usersSlice';
import useCustomSearchParams from 'hooks/useCustomSearchParams';

const Users = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useCustomSearchParams();

  useEffect(() => {
    dispatch(fetchUsersBySearchParams(searchParams));
  }, [dispatch, searchParams]);

  return <Outlet />;
};

export default Users;
