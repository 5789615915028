import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { INotification, TNotificationId } from 'types/notifications';

interface INotificationState {
  notifications: INotification[]
}

const initialState: INotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<Omit<INotification, 'id'>>) => {
      state.notifications.push({
        id: uuid(),
        type: action.payload.type,
        message: action.payload.message,
      });
    },
    hideNotification: (state, action: PayloadAction<TNotificationId>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload,
      );
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
