import { useFormik } from 'formik';
import React, { useState } from 'react';

import { Select } from 'components/forms';
import { Modal } from 'components/feedBacks';
import { TServices } from 'types/services';
import { SERVICE_TYPES } from 'constants/rules';
import { closeModal } from 'store/slices/modalSlice';
import { TServiceType, IRuleService } from 'types/rules';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';
import {
  ButtonGroup,
  OutlinedButton,
  ContainedButton,
} from 'components/buttons';

import s from './ModalServicesSelects.module.css';

const SERVICES = [
  {
    label: 'Сервисы',
    type: SERVICE_TYPES.SERVICES,
  },
  {
    label: 'Группа Сервисов',
    type: SERVICE_TYPES.SERVICES_GROUPS,
  },
] as const;

interface IModalServicesSelectsProps {
  onSubmit: (newLocations: IRuleService[]) => void,
  id: string,
  services: Array<IRuleService | undefined> | undefined,
}

const ModalServicesSelects = ({
  onSubmit,
  id,
  services: usedServices,
}: IModalServicesSelectsProps) => {
  const dispatch = useAppDispatch();
  const { services, servicesGroups } = useAppSelector((state) => state.positions);

  const emptyId = '';

  const SERVICES_DATA = {
    [SERVICE_TYPES.SERVICES]: services,
    [SERVICE_TYPES.SERVICES_GROUPS]: servicesGroups,
  };

  const [activeServiceType, setActiveServiceType] = useState<TServiceType>(SERVICE_TYPES.SERVICES);

  const getUsedServicesByType = (type: TServiceType) => usedServices && usedServices
    .filter((usedService) => usedService?.type === type);

  const getUnusedServices = (type: TServiceType) => {
    const usedServicesByType = getUsedServicesByType(type);

    const allServices = SERVICES_DATA[type];

    return allServices?.filter((service) => (
      !usedServicesByType?.find((usedService) => Number(usedService?.id) === Number(service[0]))
    ));
  };

  const isEmptyServicesList = !getUnusedServices(activeServiceType)?.length;

  const getOptions = (ruleServices: TServices | undefined) => ruleServices?.map((service) => ({
    value: service[0].toString(),
    label: `${service[0]} - ${service[1]}`,
  }));

  const getOptionsByType = (type: TServiceType) => getOptions(getUnusedServices(type));

  const initialValues = {
    [SERVICE_TYPES.SERVICES]: [{
      type: SERVICE_TYPES.SERVICES,
      id: emptyId,
    }],
    [SERVICE_TYPES.SERVICES_GROUPS]: [{
      type: SERVICE_TYPES.SERVICES_GROUPS,
      id: emptyId,
    }],
  };

  const {
    values,
    handleReset,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: (newServices) => {
      const newServicesWithoutEmpty = newServices[SERVICE_TYPES.SERVICES]
        .filter((newService) => !!newService.id);

      const newServicesGroupsWithoutEmpty = newServices[SERVICE_TYPES.SERVICES_GROUPS]
        .filter((newService) => !!newService.id);

      const newAllServices = [...newServicesWithoutEmpty, ...newServicesGroupsWithoutEmpty];

      const newServicesIdToNumber = newAllServices.map((newService) => ({
        type: newService.type,
        id: Number(newService.id),
      }));

      onSubmit(newServicesIdToNumber);
      dispatch(closeModal(id));
      resetForm();
    },
  });

  const setServices = (newServiceIds?: string | string[]) => {
    if (Array.isArray(newServiceIds)) {
      const newServices = newServiceIds.map((newServiceId) => ({
        type: activeServiceType,
        id: newServiceId,
      }));

      setFieldValue(`[${activeServiceType}]`, newServices);
    }

    if (!newServiceIds) {
      const emptyService = {
        type: activeServiceType,
        id: emptyId,
      };

      setFieldValue(`[${activeServiceType}]`, [emptyService]);
    }
  };

  const activeValues = values[activeServiceType];

  const getValues = () => activeValues.map((service) => service.id.toString());

  const placeholder = isEmptyServicesList ? 'Нет доступных сервисов' : 'Введите названия';

  return (
    <Modal id={id}>
      <form className={s.container}>
        <h3 className={s.title}>Выберите сервис или группу сервисов</h3>
        <ButtonGroup>
          {SERVICES.map(({ label, type }) => (
            <button
              type="button"
              className={activeServiceType === type ? 'active' : undefined}
              onClick={() => setActiveServiceType(type)}
              key={label}
            >
              {label}
            </button>
          ))}
        </ButtonGroup>
        <div className={s.selects}>
          <Select
            options={getOptionsByType(activeServiceType)}
            value={getValues()}
            onChange={setServices}
            placeholder={placeholder}
            isClearable
            isSearchable
            isMulti
            isDisabled={isEmptyServicesList}
            indicator="search"
            variant="outlined"
          />
        </div>
        <div className={s.buttonsWrapper}>
          <OutlinedButton
            onClick={(e) => {
              dispatch(closeModal(id));
              handleReset(e);
            }}
          >
            Отмена
          </OutlinedButton>
          <ContainedButton
            onClick={() => handleSubmit()}
          >
            Добавить
          </ContainedButton>
        </div>
      </form>
    </Modal>
  );
};

export default ModalServicesSelects;
