import React from 'react';

import s from './WhiteContainer.module.css';

interface IWhiteContainerProps{
  headline: string | null,
  children: React.ReactNode,
}

const WhiteContainer = ({ headline, children }: IWhiteContainerProps) => (
  <div className={s.container}>
    <h1>{headline}</h1>
    <div>{children}</div>
  </div>
);

export default WhiteContainer;
