import React from 'react';
import Cookies from 'js-cookie';

import LoginForm from './LoginForm/LoginForm';

const Login = () => {
  const userLoginInfo = Cookies.get('user-login-info');
  const rememberedLogin = userLoginInfo && JSON.parse(userLoginInfo);

  return (
    <div>
      <LoginForm rememberedLogin={rememberedLogin} />
    </div>
  );
};

export default Login;
