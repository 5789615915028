const dateFormat = (dateString: string | null) => {
  if (!dateString) return null;

  const date = new Date(dateString);

  const year = date.getFullYear();

  let month: number | string = date.getMonth() + 1;
  if (month < 10) month = `0${month}`;

  let day: number | string = date.getDate();
  if (day < 10) day = `0${day}`;

  let hours: number | string = date.getHours();
  if (hours < 10) hours = `0${hours}`;

  let minutes: number | string = date.getMinutes();
  if (minutes < 10) minutes = `0${minutes}`;

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export default dateFormat;
