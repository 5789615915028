import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'services/api';
import { TStatus } from 'types/slices';
import { TLocations } from 'types/locations';

const fetchAllRegions = createAsyncThunk(
  'locations/fetchAllRegions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.locations.getAllRegions();

      return response.data.data && Object.entries(response.data.data);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const fetchAllAgents = createAsyncThunk(
  'locations/fetchAllAgents',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.locations.getAllAgents();

      return response.data.data && Object.entries(response.data.data);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const fetchAllTerminals = createAsyncThunk(
  'locations/fetchAllTerminals',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.locations.getAllTerminals();

      return response.data.data && Object.entries(response.data.data);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

interface ILocationsState {
  locations?: TLocations,
  agents?: TLocations,
  regions?: TLocations,
  terminals?: TLocations,
  locationsByType?: TLocations,
  error: any,
  status?: TStatus,
}

const setError = (state: ILocationsState, action: any) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const setLoading = (state: ILocationsState) => {
  state.status = 'loading';
  state.error = null;
};

const initialState: ILocationsState = {
  agents: [],
  regions: [],
  terminals: [],
  error: undefined,
  status: undefined,
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRegions.pending, setLoading)
      .addCase(fetchAllTerminals.pending, setLoading)
      .addCase(fetchAllAgents.pending, setLoading)

      .addCase(fetchAllRegions.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.regions = action.payload;
      })
      .addCase(fetchAllTerminals.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.terminals = action.payload;
      })
      .addCase(fetchAllAgents.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.agents = action.payload;
      })

      .addCase(fetchAllRegions.rejected, setError)
      .addCase(fetchAllTerminals.rejected, setError)
      .addCase(fetchAllAgents.rejected, setError);
  },
});

export default locationsSlice.reducer;

export {
  fetchAllAgents,
  fetchAllRegions,
  fetchAllTerminals,
};
