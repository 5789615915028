import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'services/api';
import { IUser } from 'types/users';
import { TStatus } from 'types/slices';
import { USERS_PER_PAGE } from 'constants/users';
import { IPagination, ISearchParams } from 'types/searchParams';

const fetchUsersBySearchParams = createAsyncThunk(
  'users/fetchUsersBySearchParams',
  async (searchParams: ISearchParams, { rejectWithValue }) => {
    try {
      const queryParamsWithLimit = {
        limit: `${USERS_PER_PAGE}`,
        ...searchParams,
      };

      const response = await api.users.getUsersByQueryParams(queryParamsWithLimit);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

interface IUsersState {
  users?: IUser[],
  pagination?: IPagination,
  status?: TStatus,
  error?: string,
}

const setError = (state: IUsersState, action: any) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const setLoading = (state: IUsersState) => {
  state.status = 'loading';
  state.error = undefined;
};

const initialState: IUsersState = {
  users: [],
  pagination: undefined,
  status: undefined,
  error: undefined,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersBySearchParams.pending, setLoading)

      .addCase(fetchUsersBySearchParams.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.users = action.payload.data;
        state.pagination = action.payload.pagination;
      })

      .addCase(fetchUsersBySearchParams.rejected, setError);
  },
});

export default usersSlice.reducer;

export { fetchUsersBySearchParams };
