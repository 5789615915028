import React from 'react';
import { useNavigate } from 'react-router-dom';

import { INewRule } from 'types/rules';
import { addNewRule } from 'store/slices/rulesSlice';
import { useAppDispatch } from 'hooks/typedReduxHooks';

import RuleForm from '../RuleForm/RuleForm';

const AddNewRule = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (rule: INewRule) => {
    dispatch(addNewRule(rule))
      .unwrap()
      .then(() => navigate('/rules'));
  };

  return (
    <RuleForm
      title="Добавление нового правила"
      onSubmit={onSubmit}
      submitButtonName="Добавить"
    />
  );
};

export default AddNewRule;
