import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'services/api';
import { TStatus } from 'types/slices';
import { ILogin, IMyInfo } from 'types/login';

const login = createAsyncThunk(
  'auth/login',
  async (data: ILogin, { rejectWithValue }) => {
    try {
      const response = await api.auth.login(data);

      if (response.data.token) {
        Cookies.set('auth-token', response.data.token, { expires: 1 });
      }

      return response.data.success;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.auth.getMe();

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

interface IAuthState {
  isAuth: boolean,
  profile?: IMyInfo,
  errorStatus?: any,
  status?: TStatus,
}

const initialState: IAuthState = {
  isAuth: false,
  profile: undefined,
  errorStatus: null,
  status: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuth = false;
      Cookies.remove('auth-token');
      Cookies.remove('user-login-info');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.errorStatus = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuth = action.payload;
        state.errorStatus = null;
        state.status = 'resolved';
      })
      .addCase(login.rejected, (state, action) => {
        state.errorStatus = action.payload;
        state.status = 'rejected';
      })

      .addCase(checkAuth.pending, (state) => {
        state.errorStatus = null;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.isAuth = action.payload.success;
        state.profile = action.payload.data;
        state.errorStatus = null;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        if (action.payload === 401) state.isAuth = false;
        state.profile = undefined;
      });
  },
});

export default authSlice.reducer;

export const { logout } = authSlice.actions;

export { login, checkAuth };
