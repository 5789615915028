import { ILogin, IMyInfo } from 'types/login';
import { IResponse, IResponseAuth } from 'types/response';

import axiosInstance from '../axios';

const auth = {
  login: (data: ILogin) => axiosInstance.post<IResponseAuth>('/auth/login', data),
  getMe: () => axiosInstance.get<IResponse<IMyInfo>>('/auth/me'),
};

export default auth;
