import React from 'react';

import logo from 'assets/svgs/Kassa24.svg';

import s from './authLayout.module.css';

interface IAuthLayoutProps {
  children: React.ReactNode,
}

const AuthLayout = ({ children }: IAuthLayoutProps) => (
  <div className={s.container}>
    <img className={s.logo} src={logo} alt="logo" />
    {children}
  </div>
);

export default AuthLayout;
