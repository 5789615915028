import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Select } from 'components/forms';
import { RULES_SORT_PARAMS as sortByOptions } from 'constants/rules';

const Sort = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = (newValue?: string | string[]) => {
    if (typeof newValue === 'string') {
      searchParams.set('sort', newValue);
    }

    !newValue && searchParams.delete('sort');

    setSearchParams(searchParams);
  };

  const sortBy = searchParams.get('sort') || undefined;

  return (
    <Select
      options={sortByOptions}
      value={sortBy}
      onChange={onChange}
      placeholder="Сортировать..."
      isClearable
      isSearchable={false}
    />
  );
};

export default Sort;
