/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillPlusSquare as PlusSquareIcon } from 'react-icons/ai';

import dateFormat from 'utils/dateFormat';
import { RULES_PER_PAGE } from 'constants/rules';
import { useAppSelector } from 'hooks/typedReduxHooks';
import Pagination from 'components/shared/Pagination/Pagination';

import s from './RulesList.module.css';
import ListItem from './ListItem/ListItem';
import Controls from './Controls/Controls';

const RulesList = () => {
  const { rules, errorStatus, pagination } = useAppSelector((state) => state.rules);

  const totalPages = pagination && Math.ceil(pagination.total / RULES_PER_PAGE);

  return (
    <>
      <div className={s.firstLine}>
        <h2>Список правил</h2>
        <Link className={s.addRule} to="/rules/add">
          <PlusSquareIcon className={s.plusSquareIcon} />
          Добавить правило
        </Link>
      </div>
      <div className={s.firstLine}>
        <Controls />
      </div>

      {totalPages ? (
        <>
          <ul className={s.list}>
            {rules?.map((rule) => (
              <ListItem
                name={rule.name}
                timeBegin={(typeof rule.time_begin === 'string' && dateFormat(rule.time_begin)) || 'Не задано'}
                timeEnd={(typeof rule.time_end === 'string' && dateFormat(rule.time_end)) || 'Не задано'}
                priority={rule.priority}
                status={rule.status}
                id={rule._id}
                key={rule._id}
              />
            ))}
          </ul>
          <Pagination
            totalPages={totalPages}
          />
        </>
      ) : (
        <h2 className={s.emptyList}>
          {(errorStatus && `Статус ошибки ${errorStatus}`) || 'Список пуст'}
        </h2>
      )}
    </>
  );
};

export default RulesList;
