import { TSearchParamName } from 'types/rules';
import { TMultiSearchParam } from 'types/searchParams';

const multiSelector = '[in]';

const setMultiParamSelector = (searchParam: TSearchParamName): TMultiSearchParam<TSearchParamName> => (
  `${searchParam}${multiSelector}`
);

const removeMultiParamSelector = (searchParam: TSearchParamName | TMultiSearchParam<TSearchParamName>) => (
  searchParam.replace(multiSelector, '') as TSearchParamName
);

export { setMultiParamSelector, removeMultiParamSelector };
