import axios from 'axios';
import Cookies from 'js-cookie';

import { BASE_URL } from 'constants/api';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = Cookies.get('auth-token');
    const newConfig = config;

    if (authToken && newConfig.headers) {
      newConfig.headers.authorization = `Bearer ${authToken}`;
    }

    return newConfig;
  },
  (error) => Promise.reject.apply(error),
);

export default axiosInstance;
