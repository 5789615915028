import React from 'react';
import { MdError as Error } from 'react-icons/md';

import s from './ErrorMessage.module.css';

interface IErrorMessageProps{
  children: string | string[],
}

const ErrorMessage = ({ children }: IErrorMessageProps) => (
  <div className={s.container}>
    <Error />
    {
      Array.isArray(children)
        ? children.map((child, key) => <span key={child}>{child}</span>)
        : <span>{children}</span>
    }
  </div>
);

export default ErrorMessage;
