/* eslint-disable react/react-in-jsx-scope */
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { useAppSelector } from 'hooks/typedReduxHooks';

import FlashMessage from './FlashMessage/FlashMessage';
import s from './FlashMessages.module.css';

const FlashMessages = () => {
  const { notifications } = useAppSelector((state) => state.notification);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TransitionGroup className={s.notifications}>
      {notifications.map(({ id, type, message }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CSSTransition
          in={!!message}
          timeout={700}
          classNames={{
            enterActive: s.alertEnterActive,
            enter: s.alertEnter,
            exitActive: s.alertExitActive,
            exit: s.alertExit,
          }}
          key={id}
        >
          <FlashMessage id={id} type={type}>
            {message}
          </FlashMessage>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default FlashMessages;
