/* eslint-disable no-underscore-dangle */
import React from 'react';

import { USERS_PER_PAGE } from 'constants/users';
import { useAppSelector } from 'hooks/typedReduxHooks';
import Pagination from 'components/shared/Pagination/Pagination';

import s from './UsersList.module.css';
import ListItem from './ListItem/ListItem';

const UsersList = () => {
  const { users, pagination } = useAppSelector((state) => state.users);

  const totalPages = pagination && Math.ceil(pagination.total / USERS_PER_PAGE);

  const isUsersListExists = !!users?.length;

  return (
    <>
      <div className={s.firstLine}>
        <h2>Список пользователей</h2>
      </div>
      {isUsersListExists ? (
        <>
          <ul className={s.list}>
            {users.map((user) => (
              <ListItem
                name={user.name}
                email={user.email}
                phone={user.phone}
                role={user.role}
                key={user._id}
              />
            ))}
          </ul>
          {totalPages && (totalPages > 1) && (
            <Pagination
              totalPages={totalPages}
            />
          )}
        </>
      ) : (
        <h2 className={s.emptyList}>Пользователи не найдены</h2>
      )}
    </>
  );
};

export default UsersList;
