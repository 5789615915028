const breadcrumbRoutes = [
  {
    path: '/',
    breadcrumb: 'Главная',
  },
  {
    path: '/rules',
    breadcrumb: null,
  },
  {
    path: '/rules/add',
    breadcrumb: 'Создание правила',
  },
  {
    path: '/rules/:id',
    breadcrumb: 'Редактирование правила',
  },
  {
    path: '/users',
    breadcrumb: 'Список пользователей',
  },
  {
    path: '/users/:id',
    breadcrumb: 'Редактирование настройки пользователя',
  },
  {
    path: '/users/add',
    breadcrumb: 'Добавление пользователя',
  },
  {
    path: '/settings',
    breadcrumb: 'Ваши настройки',
  },
];

export default breadcrumbRoutes;
