import { useEffect } from 'react';

const useOnClickOutside = (actionFunction: () => void, ref: React.RefObject<any>) => {
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        actionFunction();
      }
    };

    document.addEventListener('mousedown', handler);

    return () => document.addEventListener('mousedown', handler);
  }, [actionFunction, ref]);
};

export default useOnClickOutside;
