import { useFormik } from 'formik';
import React, { useState, useCallback, useMemo } from 'react';

import { Select } from 'components/forms';
import { Modal } from 'components/feedBacks';
import { TLocations } from 'types/locations';
import { LOCATION_TYPES } from 'constants/rules';
import { closeModal } from 'store/slices/modalSlice';
import { TLocationType, IRuleLocation } from 'types/rules';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';
import {
  ButtonGroup,
  OutlinedButton,
  ContainedButton,
} from 'components/buttons';

import s from './ModalLocationsSelects.module.css';

const LOCATIONS = [
  {
    label: 'Агент',
    type: LOCATION_TYPES.AGENTS,
  },
  {
    label: 'Регион',
    type: LOCATION_TYPES.REGIONS,
  },
  {
    label: 'Терминал',
    type: LOCATION_TYPES.TERMINALS,
  },
  {
    label: 'Игнорировать терминалы',
    type: LOCATION_TYPES.IGNORE_TERMINALS,
  },
] as const;

interface IModalLocationsSelectsProps {
  onSubmit: (newLocations: IRuleLocation[]) => void,
  id: string,
  locationType?: TLocationType,
  locations: Array<IRuleLocation | undefined> | undefined,
}

 type TInitialValues = {
    type: TLocationType,
    ids: string[],
    lock:boolean
  }[]

const emptyId = '';

const ModalLocationsSelects = ({
  onSubmit,
  id,
  locationType,
  locations: usedLocations,
}: IModalLocationsSelectsProps) => {
  const dispatch = useAppDispatch();

  const initialValues: TInitialValues = LOCATIONS.map(({ type }) => ({
    type,
    ids: [emptyId],
    lock: false,
  }));

  const {
    values,
    handleReset,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: (newLocations) => {
      const newLocationsWithoutEmptyId = newLocations.map(({ type, ids }) => ({
        type,
        ids: ids.filter((locationId) => locationId !== emptyId),
        lock: type === LOCATION_TYPES.IGNORE_TERMINALS,
      }));

      const newLocationsWithoutEmpty = newLocationsWithoutEmptyId.filter(({ ids }) => ids.length);

      const newLocationsIdsToNumber = newLocationsWithoutEmpty.map(({ type, ids, lock }) => ({
        type,
        ids: ids.map((locationId) => Number(locationId)),
        lock,
      }));

      onSubmit(newLocationsIdsToNumber);
      dispatch(closeModal(id));
      resetForm();
    },
  });
  const { agents, regions, terminals } = useAppSelector((state) => state.locations);

  const [activeLocationType, setActiveLocationType] = useState<TLocationType>(locationType || LOCATION_TYPES.AGENTS);

  const isLocationTypeGiven = !!locationType;

  const activeLocationIndex = useMemo(() => activeLocationType - 1, [activeLocationType]);

  const getUsedLocationsByType = useCallback((type: TLocationType, isFiltered = true) => {
    const locationObjectByType = usedLocations
      ?.find((usedLocation) => usedLocation?.type === type);
    const locationObjectByTypeCopy = { ...locationObjectByType };

    if (isFiltered) {
      if (type === LOCATION_TYPES.TERMINALS) {
        const locationCheckedList:any = values[LOCATION_TYPES.TERMINALS];
        if (locationCheckedList?.ids[0]) {
          return Object.assign(locationObjectByTypeCopy ?? {}, locationCheckedList);
        }
      }
      if (type === LOCATION_TYPES.IGNORE_TERMINALS) {
        const locationCheckedList:any = values[LOCATION_TYPES.TERMINALS - 1];

        if (locationCheckedList?.ids[0]) {
          return Object.assign(locationObjectByTypeCopy ?? {}, locationCheckedList);
        }
      }
    }

    return locationObjectByType;
  }, [usedLocations, values]);

  const getUnusedLocations = useCallback((type: TLocationType) => {
    const LOCATIONS_DATA = {
      [LOCATION_TYPES.AGENTS]: agents,
      [LOCATION_TYPES.REGIONS]: regions,
      [LOCATION_TYPES.TERMINALS]: terminals,
      [LOCATION_TYPES.IGNORE_TERMINALS]: terminals,
    };
    const usedLocationsByType = getUsedLocationsByType(type);
    const usedLocationsByTypeCopy = { ...usedLocationsByType };
    let usedLocationsCurrent = usedLocationsByType;

    const locations = LOCATIONS_DATA[type];

    if (type === LOCATION_TYPES.TERMINALS) {
      const usedLocationIgnoreTerminal = getUsedLocationsByType(LOCATION_TYPES.IGNORE_TERMINALS, false);
      if (usedLocationsByTypeCopy && usedLocationIgnoreTerminal) {
        usedLocationsCurrent = Object.assign(usedLocationsByTypeCopy, usedLocationIgnoreTerminal);
      } else if (usedLocationIgnoreTerminal) {
        usedLocationsCurrent = usedLocationIgnoreTerminal;
      }
    } else if (type === LOCATION_TYPES.IGNORE_TERMINALS) {
      const usedLocationTerminal = getUsedLocationsByType(LOCATION_TYPES.TERMINALS, false);
      if (usedLocationsByTypeCopy && usedLocationTerminal) {
        usedLocationsCurrent = Object.assign(usedLocationsByTypeCopy, usedLocationTerminal);
      } else if (usedLocationTerminal) {
        usedLocationsCurrent = usedLocationTerminal;
      }
    }

    return locations?.filter((location) => (
      !usedLocationsCurrent?.ids.find((usedId:any) => Number(usedId) === Number(location[0]))
    ));
  }, [getUsedLocationsByType, agents, regions, terminals]);

  const isEmptyLocationsList = useMemo(
    () => !getUnusedLocations(activeLocationType)?.length,
    [getUnusedLocations, activeLocationType],
  );

  const getOptions = (locations: TLocations | undefined) => locations?.map((location) => ({
    value: location[0].toString(),
    label: `${location[0]} - ${location[1]}`,
  }));

  const getOptionsByType = useMemo(
    () => getOptions(getUnusedLocations(activeLocationType)),
    [activeLocationType, getUnusedLocations],
  );

  const setLocations = useCallback((newLocations?: string | string[]) => {
    if (Array.isArray(newLocations)) {
      setFieldValue(`[${activeLocationIndex}].ids`, newLocations);
    }

    if (!newLocations) setFieldValue(`[${activeLocationIndex}].ids`, [emptyId]);
  }, [setFieldValue, activeLocationIndex]);

  const getValues = useCallback(
    () => values[activeLocationIndex].ids.map((locationId) => locationId.toString()),
    [values, activeLocationIndex],
  );

  const title = isLocationTypeGiven ? 'Выберите дополнительное местоположение' : 'Выберите тип местоположения';

  const placeholder = isEmptyLocationsList ? 'Нет доступных местоположении' : 'Введите названия';

  return (
    <Modal id={id}>
      <form className={s.container}>
        <h3 className={s.title}>{title}</h3>
        {isLocationTypeGiven || (
          <ButtonGroup>
            {LOCATIONS.map(({ label, type }) => (
              <button
                type="button"
                className={activeLocationType === type ? 'active' : undefined}
                onClick={() => setActiveLocationType(type)}
                key={label}
              >
                {label}
              </button>
            ))}
          </ButtonGroup>
        )}
        <div className={s.selects}>
          <Select
            options={getOptionsByType}
            value={getValues()}
            onChange={setLocations}
            placeholder={placeholder}
            isClearable
            isSearchable
            isMulti
            isDisabled={isEmptyLocationsList}
            indicator="search"
            variant="outlined"
          />
        </div>
        <div className={s.buttonsWrapper}>
          <OutlinedButton
            onClick={(e) => {
              dispatch(closeModal(id));
              handleReset(e);
            }}
          >
            Отмена
          </OutlinedButton>
          <ContainedButton
            onClick={() => handleSubmit()}
          >
            Добавить
          </ContainedButton>
        </div>
      </form>
    </Modal>
  );
};

export default ModalLocationsSelects;
