import React, { useMemo } from 'react';
import classNames from 'classnames';
import { GoPrimitiveDot as Dot } from 'react-icons/go';

import { RULES_PRIORITY, USER_ROLES } from 'constants/rules';

import useUser from 'hooks/useUser';
import s from './Label.module.css';

interface ILabelProps {
  children: React.ReactNode,
}

const Label = ({ children }: ILabelProps) => {
  const { profile } = useUser();

  const prioritiesForUserRole = useMemo(() => RULES_PRIORITY[profile?.role ?? USER_ROLES.ADMIN], [profile?.role]);
  const findedRule = useMemo(
    () => prioritiesForUserRole.find((rule:any) => children === rule.value),
    [prioritiesForUserRole],
  );

  return (
    <span className={classNames(s.container, s[findedRule.color])}>
      <Dot className={s.dot} />
      {findedRule.label}
    </span>
  );
};

export default Label;
