import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';

import { ILogin } from 'types/login';
import authSchema from 'yupSchemas/authSchema';
import { login } from 'store/slices/authSlice';
import WhiteContainer from 'components/displays';
import { ErrorMessage } from 'components/feedBacks';
import { ContainedButton } from 'components/buttons';
import { Switch, TextField, PasswordField } from 'components/forms';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';

import s from './LoginForm.module.css';

interface ILoginFormProps {
  rememberedLogin?: ILogin,
}

const LoginForm = ({ rememberedLogin }: ILoginFormProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    rememberedLogin && dispatch(login(rememberedLogin));
  }, [rememberedLogin, dispatch]);

  const [isRemember, setIsRemember] = useState(false);

  const { errorStatus, status } = useAppSelector((state) => state.auth);

  const errorMessage = errorStatus === 401 ? 'Введен неправильный логин или пароль' : null;

  const handleToggle = () => (isRemember ? setIsRemember(false) : setIsRemember(true));

  const defaultValues: ILogin = {
    login: '',
    password: '',
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: defaultValues,
    validationSchema: authSchema,
    onSubmit: (authData) => {
      dispatch(login(authData));

      if (isRemember) Cookies.set('user-login-info', JSON.stringify(authData));
    },
  });

  return (
    <WhiteContainer headline="Авторизация">
      <form onSubmit={handleSubmit}>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <div className={s.textFields}>
          <TextField
            type="text"
            id="login"
            label="Введите логин"
            labelOnFocus="Логин"
            value={values.login}
            onChange={handleChange}
            isError={!!(errors.login && touched.login)}
          />
          {(touched.login && errors.login)
          && <ErrorMessage>{errors.login}</ErrorMessage>}
          <PasswordField
            label="Введите пароль"
            labelOnFocus="Пароль"
            id="password"
            value={values.password}
            onChange={handleChange}
            isError={!!(errors.password && touched.password)}
          />
          {(touched.password && errors.password)
          && <ErrorMessage>{errors.password}</ErrorMessage>}
        </div>
        <div className={s.rememberMe}>
          <Switch
            label="Запомнить меня"
            id="rememberLogin"
            onChange={handleToggle}
            checked={isRemember}
          />
        </div>
        <div className={s.buttonWrapper}>
          <ContainedButton
            type="submit"
            disabled={status === 'loading'}
          >
            {status === 'loading' ? 'Авторизация...' : 'Авторизоваться'}
          </ContainedButton>
        </div>
      </form>
    </WhiteContainer>
  );
};

export default LoginForm;
