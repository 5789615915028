import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import UserLayout from 'views/layouts/UserLayout';
import { checkAuth } from 'store/slices/authSlice';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';

interface IPrivateRouteProps {
  children: React.ReactNode,
}

const PrivateRoute = ({ children }: IPrivateRouteProps) => {
  const dispatch = useAppDispatch();

  const { isAuth } = useAppSelector((state) => state.auth);

  const location = useLocation();
  const url = new URLSearchParams();
  url.set('redirect', location.pathname + location.search);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  return (
    <UserLayout>
      {isAuth ? children : <Navigate to={{ pathname: '/login', search: url.toString() }} />}
    </UserLayout>
  );
};

export default PrivateRoute;
