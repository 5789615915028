import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import useSearch from 'hooks/useSearch';
import React, { useState, useRef } from 'react';
import { BiSearch as Search, BiX as DeleteIcon } from 'react-icons/bi';
import { IoChevronDownSharp as Down } from 'react-icons/io5';

import useOnClickOutside from 'hooks/useOnClickOutside';

import s from './Dropdown.module.css';

interface IDropdownProps {
  data: string[],
  onClickDelete: (item: string) => void,
}

const Dropdown = ({ data, onClickDelete }: IDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>();
  const [searchedData, search, setSearch] = useSearch(data);

  const endingOfWord = data.length > 4 ? 'ов' : 'а';
  const placeholder = data.length === 0
    ? 'Нет записей'
    : `Имеется ${data.length} пункт${endingOfWord}`;

  const handleSelect = (item: string) => {
    if (item === selected) setSelected('');
    else setSelected(item);
    setIsOpen(false);
    setSearch('');
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(() => setIsOpen(false), dropdownRef);

  return (
    <div className={s.container} ref={dropdownRef}>
      <div
        className={classNames(s.input, isOpen ? s.focus : null)}
        onKeyPress={(e) => e.stopPropagation()}
        role="textbox"
        tabIndex={0}
        aria-label="dropdown"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {(data.length === 1 ? data[0] : selected) || placeholder}
      </div>
      {data.length > 1 && <Down className={classNames(s.icon, isOpen ? s.rotate : null)} focusable="false" />}
      {(data.length > 1 && isOpen) && (
        <div className={s.content}>
          <input
            className={s.search}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Search className={s.searchIcon} />
          <hr />
          {searchedData && searchedData.map((item: string) => {
            const key = uuid();

            return (
              <div
                className={classNames(s.item, selected === item ? s.selected : null)}
                key={key}
              >
                <div
                  className="w-full"
                  onClick={() => handleSelect(item)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => handleSelect(item)}
                >
                  {item}
                </div>
                <button
                  className="w-4 h-4 text-gray-96 hover:text-black"
                  type="button"
                  onClick={() => onClickDelete(item)}
                >
                  <DeleteIcon />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
