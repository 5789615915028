/* eslint-disable import/prefer-default-export */
import { IoDocumentText } from 'react-icons/io5';

export const HEADER_NAVS = [
  {
    name: 'Правила',
    icon: IoDocumentText,
    to: '/rules',
  },
];
