import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GoChevronLeft as LeftIcon, GoChevronRight as RightIcon } from 'react-icons/go';

import s from './Pagination.module.css';

interface IPaginationProps {
  totalPages: number,
}

const Pagination = ({ totalPages }: IPaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialPage = +(searchParams.get('page') || 1);

  const [currentPage, setCurrentPage] = useState<number>(initialPage);

  useEffect(() => {
    if (currentPage > totalPages) setCurrentPage(totalPages);
    if (currentPage < 1) setCurrentPage(1);
  }, [currentPage, totalPages]);

  useEffect(() => {
    const move = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') setCurrentPage((prev) => (prev === 1 ? 1 : +prev - 1));
      if (event.key === 'ArrowRight') setCurrentPage((prev) => (prev === totalPages ? totalPages : +prev + 1));
    };
    document.body.addEventListener('keydown', move);

    return () => document.body.removeEventListener('keydown', move);
  }, [currentPage, totalPages]);

  useEffect(() => {
    searchParams.set('page', `${currentPage}`);
    setSearchParams(searchParams);
  }, [currentPage, setSearchParams, searchParams]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className={s.container}>
      <button
        className={s.prev}
        type="button"
        onClick={() => setCurrentPage((prev) => +prev - 1)}
        disabled={currentPage === 1}
      >
        <LeftIcon className={s.icon} />
      </button>
      <input
        type="number"
        className={s.currentPage}
        value={currentPage}
        onChange={(e) => setCurrentPage(+e.target.value)}
        min={1}
        max={totalPages}
      />
      <span className={s.totalPages}>{totalPages}</span>
      <button
        className={s.next}
        type="button"
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((prev) => +prev + 1)}
      >
        <RightIcon />
      </button>
    </div>
  );
};

export default Pagination;
