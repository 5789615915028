import React from 'react';

import s from './OutlinedButton.module.css';

interface IOutlinedButtonProps{
  type?: 'button' | 'submit',
  onClick: (e?: React.MouseEvent) => void,
  children: React.ReactNode | string,
}

const OutlinedButton = ({ type = 'button', onClick, children }: IOutlinedButtonProps) => (
  <button
    className={s.button}
    type={type === 'submit' ? 'submit' : 'button'}
    onClick={onClick}
  >
    {children}
  </button>
);

export default OutlinedButton;
