import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'services/api';
import { TStatus } from 'types/slices';
import { TServices } from 'types/services';

const fetchAllServices = createAsyncThunk(
  'positions/fetchAllServices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.services.getAllServices();

      return response.data.data && Object.entries(response.data.data);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const fetchAllGroups = createAsyncThunk(
  'positions/fetchAllGroups',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.services.getAllGroups();

      return response.data.data && Object.entries(response.data.data);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const setError = (state: any, action: any) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const setLoading = (state: any) => {
  state.status = 'loading';
  state.error = null;
};

interface IPositionsState {
  positions: string[],
  services?: TServices,
  servicesGroups?: TServices,
  status?: TStatus,
  error?: string,
}

const initialState: IPositionsState = {
  positions: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
  services: [],
  servicesGroups: [],
  status: undefined,
  error: undefined,
};

const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllServices.pending, setLoading)
      .addCase(fetchAllGroups.pending, setLoading)

      .addCase(fetchAllServices.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.services = action.payload;
      })
      .addCase(fetchAllGroups.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.servicesGroups = action.payload;
      })

      .addCase(fetchAllServices.rejected, setError)
      .addCase(fetchAllGroups.rejected, setError);
  },
});

export default positionsSlice.reducer;

export { fetchAllGroups, fetchAllServices };
