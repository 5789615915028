import React from 'react';
import classNames from 'classnames';

import s from './TextField.module.css';

interface ITextFieldProps {
  type?: 'text' | 'number',
  id?: string,
  label: string,
  labelOnFocus: string,
  value?: string | number,
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onClick?: (e: React.MouseEvent) => void,
  onFocus?: (e: React.FocusEvent) => void,
  isError?: boolean,
  multiline?: boolean,
  icon?: React.ReactElement,
  name?: string,
}

const TextField = ({
  type = 'text',
  id,
  label,
  labelOnFocus,
  value,
  onChange,
  onClick,
  onFocus,
  isError,
  multiline,
  icon,
  name,
}: ITextFieldProps) => {
  type ClassNameType = typeof s;

  const editorClassName = (className: ClassNameType) => (isError ? classNames(className, s.error) : className);

  return (
    <div className={s.container}>
      {multiline ? (
        <textarea
          className={`${editorClassName(s.input)} ${s.textarea}`}
          placeholder={label}
          id={id}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          name={name}
          autoComplete="off"
        />
      ) : (
        <input
          className={editorClassName(s.input)}
          type={type}
          placeholder={label}
          id={id}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          name={name}
          autoComplete="off"
        />
      )}
      <label className={editorClassName(s.borderLabel)} htmlFor={id}>
        {labelOnFocus}
      </label>
      {icon ? <span className={s.icon}>{icon}</span> : null}
    </div>
  );
};

export default TextField;
