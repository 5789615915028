/* eslint-disable no-underscore-dangle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'services/api';
import { TStatus } from 'types/slices';
import { RULES_PER_PAGE } from 'constants/rules';
import { IRule, TRuleId, INewRule } from 'types/rules';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import { IPagination, ISearchParams } from 'types/searchParams';
import { showNotification } from 'store/slices/notificationSlice';

const fetchRulesByParams = createAsyncThunk(
  'rules/fetchRulesByParams',
  async (searchParams: ISearchParams, { rejectWithValue, dispatch }) => {
    try {
      const queryParams = {
        limit: `${RULES_PER_PAGE}`,
        ...searchParams,
      };

      const response = await api.rules.getRulesByQueryParams(queryParams);

      return response.data;
    } catch (error: any) {
      const message = error.response.data.error;
      dispatch(showNotification({ type: NOTIFICATION_TYPES.ERROR, message }));

      return rejectWithValue(error.response.status);
    }
  },
);

const fetchRuleById = createAsyncThunk(
  'rules/fetchRuleById',
  async (id: TRuleId, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.rules.getRuleById(id);

      return response.data?.data;
    } catch (error: any) {
      const message = error.response.data.error;
      dispatch(showNotification({ type: NOTIFICATION_TYPES.ERROR, message }));

      return rejectWithValue(error.response.status);
    }
  },
);

const deleteRule = createAsyncThunk(
  'rules/deleteRule',
  async (id: TRuleId, { rejectWithValue, dispatch }) => {
    try {
      await api.rules.deleteRule(id);

      dispatch(showNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Правило успешно удалено' }));

      return id;
    } catch (error: any) {
      const message = error.response.data.error;
      dispatch(showNotification({ type: NOTIFICATION_TYPES.ERROR, message }));

      return rejectWithValue(error.response.status);
    }
  },
);

const addNewRule = createAsyncThunk(
  'rules/addNewRule',
  async (rule: INewRule, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.rules.postNewRule(rule);

      dispatch(showNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Правило успешно добавлено' }));

      return response.data.data;
    } catch (error: any) {
      const message = error.response.data.error;
      dispatch(showNotification({ type: NOTIFICATION_TYPES.ERROR, message }));

      return rejectWithValue(error.response.status);
    }
  },
);

interface IEditRuleArgs {
  editedRule: IRule | INewRule,
  id: TRuleId,
}

const editRule = createAsyncThunk(
  'rules/editRule',
  async ({ editedRule, id }: IEditRuleArgs, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.rules.editRule(editedRule, id);

      dispatch(showNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Правило успешно отредактировано' }));

      return response.data?.data;
    } catch (error: any) {
      const message = error.response.data.error;
      dispatch(showNotification({ type: NOTIFICATION_TYPES.ERROR, message }));

      return rejectWithValue(error.response.status);
    }
  },
);

interface IRulesState {
  rules?: IRule[],
  pagination?: IPagination,
  ruleById?: IRule,
  status?: TStatus,
  errorStatus?: any,
}

const setError = (state: IRulesState, action: any) => {
  state.status = 'rejected';
  state.errorStatus = action.payload;
};

const setLoading = (state: IRulesState) => {
  state.status = 'loading';
  state.errorStatus = undefined;
};

const initialState: IRulesState = {
  rules: [],
  pagination: undefined,
  ruleById: undefined,
  status: undefined,
  errorStatus: undefined,
};

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    clearRuleById: (state) => {
      state.ruleById = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRulesByParams.pending, setLoading)
      .addCase(deleteRule.pending, setLoading)
      .addCase(addNewRule.pending, setLoading)
      .addCase(fetchRuleById.pending, setLoading)
      .addCase(editRule.pending, setLoading)

      .addCase(fetchRulesByParams.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.rules = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchRuleById.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.ruleById = action.payload;
      })
      .addCase(deleteRule.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.rules = state.rules && state.rules.filter((rule) => rule._id !== action.payload);
      })
      .addCase(addNewRule.fulfilled, (state) => {
        state.status = 'resolved';
      })
      .addCase(editRule.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.rules && state.rules
          .forEach((rule) => (action.payload && (rule._id === action.payload._id ? action.payload : rule)));
      })

      .addCase(fetchRulesByParams.rejected, setError)
      .addCase(deleteRule.rejected, setError)
      .addCase(addNewRule.rejected, setError)
      .addCase(fetchRuleById.rejected, setError)
      .addCase(editRule.rejected, setError);
  },
});

export default rulesSlice.reducer;

export const { clearRuleById } = rulesSlice.actions;

export {
  fetchRulesByParams,
  fetchRuleById,
  deleteRule,
  addNewRule,
  editRule,
};
