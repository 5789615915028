/* eslint-disable no-underscore-dangle */
import { ISearchParams } from 'types/searchParams';
import { IRule, TRuleId, INewRule } from 'types/rules';
import { IResponse, IResponseWithPagination } from 'types/response';

import axiosInstance from '../axios';

const rules = {
  getRuleById: (id: TRuleId) => axiosInstance.get<IResponse<IRule>>(`/rules/${id}`),

  deleteRule: (id: TRuleId) => axiosInstance.delete(`/rules/${id}`),

  postNewRule: (newRule: INewRule) => axiosInstance.post<IResponseWithPagination<IRule>>('/rules', newRule),

  editRule: (editedRule: IRule | INewRule, id: TRuleId) => axiosInstance
    .put<IResponseWithPagination<IRule>>(`/rules/${id}`, editedRule),

  getRulesByQueryParams: (queryParams: ISearchParams) => axiosInstance
    .get<IResponseWithPagination<IRule[]>>('/rules', { params: queryParams }),
};

export default rules;
