import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { IoTrashSharp as TrashIcon } from 'react-icons/io5';
import { RiPencilFill as PencilIcon } from 'react-icons/ri';

import WhiteContainer from 'components/displays';
import { deleteRule } from 'store/slices/rulesSlice';
import { Modal, Tooltip } from 'components/feedBacks';
import { openModal, closeModal } from 'store/slices/modalSlice';
import { ContainedButton, OutlinedButton } from 'components/buttons';
import { useAppDispatch } from 'hooks/typedReduxHooks';
import { RULE_STATUS_COLORS, RULE_STATUS_LABELS } from 'constants/rules';

import Label from './Label/Label';
import s from './ListItem.module.css';

const MAX_NAME_LENGTH = 45;

interface IListItemProps {
  name: string,
  timeBegin?: string,
  timeEnd?: string,
  priority: number,
  status: number,
  id: string,
}

const ListItem = ({
  name,
  timeBegin,
  timeEnd,
  priority,
  status,
  id,
}: IListItemProps) => {
  const dispatch = useAppDispatch();

  const modalId = `modal${id}`;

  const isLongName = name.length > MAX_NAME_LENGTH;
  const shortName = isLongName ? `${name.slice(0, MAX_NAME_LENGTH)}...` : name;

  return (
    <>
      <li className={s.container}>
        <Tooltip
          className={s.ruleName}
          tooltip={isLongName ? name : null}
        >
          {shortName}
        </Tooltip>
        <div className={s.time}>
          <div className={s.label}>Начало:</div>
          <div>{timeBegin}</div>
        </div>
        <div className={s.time}>
          <div className={s.label}>Окончание:</div>
          <div>{timeEnd}</div>
        </div>
        <Label>{priority}</Label>
        <div className={s.statusBasis}>
          <div className={classNames(s.status, s[RULE_STATUS_COLORS[status]])}>
            {RULE_STATUS_LABELS[status]}
          </div>
        </div>
        <Link className={s.edit} to={`/rules/${id}`}><PencilIcon /></Link>
        <button
          className={s.removeButton}
          type="button"
          onClick={() => dispatch(openModal(modalId))}
        >
          <TrashIcon />
        </button>
      </li>
      <Modal id={modalId}>
        <WhiteContainer headline="Удаление правила">
          <div className={s.modalMessage}>
            Вы действительно хотите удалить правило
            <i>{` "${name}" `}</i>
            ?
          </div>
          <div className={s.modalButtons}>
            <OutlinedButton
              type="button"
              onClick={() => dispatch(closeModal(modalId))}
            >
              Отмена
            </OutlinedButton>
            <ContainedButton
              type="button"
              onClick={() => {
                dispatch(deleteRule(id));
                dispatch(closeModal(modalId));
              }}
            >
              Да, удалить
            </ContainedButton>
          </div>
        </WhiteContainer>
      </Modal>
    </>
  );
};

export default ListItem;
