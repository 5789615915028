import React from 'react';
import classNames from 'classnames';

import s from './Radio.module.css';

const COLORS = {
  text: {
    blue: 'text-blue',
    green: 'text-green',
    yellow: 'text-yellow',
    red: 'text-red',
    gray: 'text-gray-dark',
    lightGreen: s.lightGreen,
    orange: s.orange,
    lightRed: s.lightRed,
  },
  border: {
    blue: 'border-blue',
    green: 'border-green',
    yellow: 'border-yellow',
    red: 'border-red',
    gray: 'border-gray-dark',
    lightGreen: 'border-[#96bd5c]',
    orange: 'border-[orange]',
    lightRed: 'border-[#ff4500]',
  },
  bg: {
    blue: 'bg-blue',
    green: 'bg-green',
    yellow: 'bg-yellow',
    red: 'bg-red',
    gray: 'bg-gray-dark',
    lightGreen: 'bg-[#96bd5c]',
    orange: 'bg-[orange]',
    lightRed: 'bg-[#ff4500]',
  },
};

interface IRadioProps {
  label: string,
  id: string,
  value?: string | number,
  name: string,
  color?: 'blue' | 'green' | 'yellow' | 'red' | 'gray',
  checked: boolean,
  onChange: (e: React.ChangeEvent) => void,
}

const Radio = ({
  label,
  id,
  value,
  name,
  color,
  checked,
  onChange,
}: IRadioProps) => (
  <label
    className={classNames(s.label, (checked && color) && COLORS.text[color])}
    htmlFor={id}
    color={color}
  >
    <input
      className={s.realRadio}
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <span className={classNames(s.customRadio, (checked && color) && COLORS.border[color])}>
      <span className={classNames(s.roundCheck, (checked && color) && COLORS.bg[color])} />
    </span>
    {label}
  </label>
);

export default Radio;
