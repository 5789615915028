/* eslint-disable no-underscore-dangle */
import React from 'react';
import { v4 as uuid } from 'uuid';

import { Dropdown } from 'components/forms';
import { LOCATION_TYPES } from 'constants/rules';
import { SquareButton } from 'components/buttons';
import getLocationName from 'utils/getLocationName';
import { IRuleLocation, TLocationType } from 'types/rules';
import { openModal, closeModal } from 'store/slices/modalSlice';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';

import s from './LocationsSection.module.css';
import ModalLocationsSelects from './ModalLocationsSelects/ModalLocationsSelects';

interface ILocationsSectionProps {
  locations?: IRuleLocation[],
  name: string,
  setFieldValue: (name: string, value: any) => void,
}

const LocationsSection = ({
  locations,
  name,
  setFieldValue,
}: ILocationsSectionProps) => {
  const dispatch = useAppDispatch();
  const { agents, regions, terminals } = useAppSelector((state) => state.locations);

  const modalId = 'addLocations';

  const locationsData = {
    [LOCATION_TYPES.AGENTS]: agents,
    [LOCATION_TYPES.REGIONS]: regions,
    [LOCATION_TYPES.TERMINALS]: terminals,
    [LOCATION_TYPES.IGNORE_TERMINALS]: terminals,
  };

  const locationsNames = {
    [LOCATION_TYPES.AGENTS]: 'агент',
    [LOCATION_TYPES.REGIONS]: 'регион',
    [LOCATION_TYPES.TERMINALS]: 'терминал',
    [LOCATION_TYPES.IGNORE_TERMINALS]: 'игнорировать терминалы',
  };

  const addLocations = (newLocations: IRuleLocation[]) => {
    newLocations.forEach((newLocation, index) => {
      const locationWithSameType = locations?.find((location) => location.type === newLocation.type);
      const indexOfLocationWithSameType = locations?.findIndex((location) => location.type === newLocation.type);

      if (locationWithSameType) {
        const concatedLocationIds = locationWithSameType.ids.concat(newLocation.ids);

        setFieldValue(`locations[${indexOfLocationWithSameType}].ids`, concatedLocationIds);
      } else {
        const newIndex = (locations?.length || 0) + index;

        setFieldValue(`locations[${newIndex}]`, newLocation);
      }
    });
  };

  const removeLocationsByType = (typeToRemove: TLocationType) => {
    if (locations?.length === 1) {
      setFieldValue(name, undefined);
    } else {
      const filteredLocations = locations?.filter(({ type }) => type !== typeToRemove);

      setFieldValue(name, filteredLocations);
    }
  };

  const getLocationsForDropdown = (location: IRuleLocation) => location.ids?.map(
    (id) => {
      const locationName = getLocationName(id, locationsData[location.type]);

      return `${id} - ${locationName}`;
    },
  );

  return (
    <>
      <div className={s.container}>
        <div className={s.title}>
          <h2>Местоположения</h2>
          <SquareButton
            purpose="add"
            onClick={() => dispatch(openModal(modalId))}
          />
        </div>
        {locations?.map((location, index) => {
          if (!location) return null;

          const key = uuid();

          const modalIdWithType = modalId + location.type.toString();

          const addAdditionalLocations = (newLocations: IRuleLocation[]) => {
            const additionalLocations = newLocations[0];

            const concatedIds = location.ids.concat(additionalLocations.ids);

            setFieldValue(`locations[${index}].ids`, concatedIds);

            dispatch(closeModal(modalIdWithType));
          };

          const removeLocationByName = (nameToRemove: string) => {
            const idToRemove = Number(nameToRemove.split('-')[0]);
            const newIds = locations[index].ids.filter((id) => id !== idToRemove);

            setFieldValue(`locations[${index}].ids`, newIds);
          };

          return (
            <div className={s.dropdowns} key={location._id || key}>
              <span className={s.label}>{`${index + 1}. Тип: ${locationsNames[location.type]}:`}</span>
              <div>
                <Dropdown data={getLocationsForDropdown(location)} onClickDelete={removeLocationByName} />
                <SquareButton
                  purpose="remove"
                  onClick={() => removeLocationsByType(location.type)}
                />
                <SquareButton
                  purpose="add"
                  onClick={() => dispatch(openModal(modalIdWithType))}
                />
              </div>
              <ModalLocationsSelects
                id={modalIdWithType}
                onSubmit={addAdditionalLocations}
                locationType={location.type}
                locations={locations}
              />
            </div>
          );
        })}
      </div>
      <ModalLocationsSelects
        id={modalId}
        onSubmit={addLocations}
        locations={locations}
      />
    </>
  );
};

export default LocationsSection;
