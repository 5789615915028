import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { CgMathPlus, CgMathMinus } from 'react-icons/cg';

import { IRulePosition } from 'types/rules';
import { Modal, Tooltip } from 'components/feedBacks';
import { closeModal } from 'store/slices/modalSlice';
import { useAppDispatch } from 'hooks/typedReduxHooks';
import { ContainedButton, OutlinedButton } from 'components/buttons';
import { MAX_COUNT_POSITIONS, MIN_COUNT_POSITIONS, DEFAULT_COUNT_POSITIONS } from 'constants/rules';

import s from './ModalButtons.module.css';

interface IPositionsArray {
  position: number,
  items: never[],
}

interface IModalButtonsProps {
  title: string,
  id: string,
  positions?: IRulePosition[],
  onSubmit: (positions: IPositionsArray[]) => void,
}

const ModalButtons = ({
  title,
  id,
  positions,
  onSubmit,
}: IModalButtonsProps) => {
  const dispatch = useAppDispatch();
  const POSITIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const existPositions = positions && positions.map((position) => position.position);

  const [activePositions, setActivePositions] = useState<number[]>([]);

  const positionsCount = activePositions.length;

  const setMultipleActivePositions = (count: number) => {
    setActivePositions(Array.from(Array(count).keys()));
  };

  useEffect(() => {
    setMultipleActivePositions(DEFAULT_COUNT_POSITIONS);
  }, []);

  const onClickPlus = () => {
    positionsCount < MAX_COUNT_POSITIONS && setMultipleActivePositions(positionsCount + 1);
  };

  const onClickMinus = () => {
    positionsCount > MIN_COUNT_POSITIONS && setMultipleActivePositions(positionsCount - 1);
  };

  const isActive = (position: number) => (
    !!activePositions.some((activePosition) => activePosition === position)
  );

  const buttonClassName = (position: number) => {
    if (isActive(position)) {
      return classNames(s.button, s.active);
    }

    return s.button;
  };

  const handleSelect = (position: number) => {
    if (isActive(position)) {
      setActivePositions(
        activePositions.filter((activePosition) => activePosition !== position),
      );
    } else {
      setActivePositions([...activePositions, position]);
    }
  };

  const handleSubmit = () => {
    const onlyNewPositions = activePositions.filter((activePosition) => !existPositions?.includes(activePosition));
    const positionsArray = onlyNewPositions.map(
      (activePosition) => ({ position: activePosition, items: [] }),
    );
    onSubmit(positionsArray);
    setActivePositions([]);
    dispatch(closeModal(id));
  };

  return (
    <Modal id={id}>
      <div className={s.container}>
        <div className={s.inline}>
          <h3 className={s.title}>{title}</h3>
          <div className={s.counter}>
            <button
              type="button"
              className={s.circleButton}
              onClick={onClickMinus}
            >
              <CgMathMinus />
            </button>
            <Tooltip tooltip="Количество позиции для добавления">
              {positionsCount.toString()}
            </Tooltip>
            <button
              type="button"
              className={s.circleButton}
              onClick={onClickPlus}
            >
              <CgMathPlus />
            </button>
          </div>
        </div>
        <div className={s.buttonsGrid}>
          {POSITIONS.map((position) => (
            <button
              className={buttonClassName(position)}
              disabled={existPositions?.includes(position)}
              type="button"
              onClick={() => handleSelect(position)}
              key={position}
            >
              {`Позиция ${position + 1}`}
            </button>
          ))}
        </div>
        <div className={s.buttonsWrapper}>
          <OutlinedButton
            onClick={() => {
              dispatch(closeModal(id));
              setActivePositions([]);
            }}
          >
            Отмена
          </OutlinedButton>
          <ContainedButton onClick={() => handleSubmit()}>Добавить</ContainedButton>
        </div>
      </div>
    </Modal>
  );
};

export default ModalButtons;
