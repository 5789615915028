import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { IOption } from 'types/shared';
import { Select } from 'components/forms';
import { TSearchParamName } from 'types/rules';
import { setMultiParamSelector } from 'utils/multiParamSelector';

interface IFilterProps {
  options: IOption[],
  placeholder?: string,
  name: TSearchParamName,
}

const Filter = ({ options, placeholder, name }: IFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isMoreThanTwo = options.length > 2;

  const searchParamName = isMoreThanTwo ? setMultiParamSelector(name) : name;

  const onChange = (newValue?: string | string[]) => {
    if (Array.isArray(newValue)) {
      const searchParamValue = newValue.join();

      searchParams.set(searchParamName, searchParamValue);
    }

    if (typeof newValue === 'string') {
      searchParams.set(searchParamName, newValue);
    }

    !newValue && searchParams.delete(searchParamName);

    setSearchParams(searchParams);
  };

  const filter = searchParams.get(searchParamName) || undefined;

  return (
    <Select
      options={options}
      value={filter}
      onChange={onChange}
      placeholder={placeholder}
      isClearable
      isSearchable={false}
      isMulti={isMoreThanTwo}
    />
  );
};

export default Filter;
