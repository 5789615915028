import React from 'react';
import { MdOutlineMoreHoriz as MinusIcon } from 'react-icons/md';

import { USER_ROLE } from 'constants/users';

import s from './ListItem.module.css';

interface IListItemProps {
  name: string,
  email: string,
  phone?: number,
  role: 'admin' | 'manager',
}

const ListItem = ({
  name,
  email,
  phone,
  role,
}: IListItemProps) => (
  <li className={s.container}>
    <div className={s.name}>{name}</div>
    <div className={s.email}>
      {email || <MinusIcon className={s.minusIcon} />}
    </div>
    <div className={s.phone}>
      {phone || <MinusIcon className={s.minusIcon} />}
    </div>
    <div className={s.role}>
      {(role && USER_ROLE[role]) || <MinusIcon className={s.minusIcon} />}
    </div>
  </li>
);

export default ListItem;
