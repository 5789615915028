import React from 'react';

import s from './ContainedButton.module.css';

interface IContainedButtonProps {
  type?: 'button' | 'submit' | 'reset',
  onClick?: (e?: React.MouseEvent) => void,
  children: React.ReactNode | string,
  disabled?: boolean,
  name?: string,
}

const ContainedButton = ({
  type = 'button',
  onClick,
  children,
  disabled,
  name,
}: IContainedButtonProps) => (
  <button
    className={s.button}
    type={type === 'submit' ? 'submit' : 'button'}
    onClick={onClick}
    disabled={disabled}
    name={name}
  >
    {children}
  </button>
);

export default ContainedButton;
