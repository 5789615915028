/* eslint-disable no-underscore-dangle */
import React, { Fragment, useCallback } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import { Dropdown } from 'components/forms';
import { SERVICE_TYPES } from 'constants/rules';
import { SquareButton } from 'components/buttons';
import getServiceName from 'utils/getServiceName';
import { openModal } from 'store/slices/modalSlice';
import { ErrorMessage } from 'components/feedBacks';
import { IRulePosition, IRuleService } from 'types/rules';
import { useAppDispatch, useAppSelector } from 'hooks/typedReduxHooks';

import s from './PositionsSection.module.css';
import ModalButtons from './ModalButtons/ModalButtons';
import ModalServicesSelects from './ModalServicesSelects/ModalServicesSelects';

interface IPositionsSectionProps {
  positions?: IRulePosition[],
  name: string,
  setFieldValue: (name: string, value: any) => void,
  errors?: FormikErrors<IRulePosition[]> | string;
  touched?: FormikTouched<IRulePosition[]> | boolean;
}

const PositionsSection = ({
  positions,
  name,
  setFieldValue,
  errors,
  touched,
}: IPositionsSectionProps) => {
  const dispatch = useAppDispatch();
  const { services, servicesGroups } = useAppSelector((state) => state.positions);

  const servicesData = {
    [SERVICE_TYPES.SERVICES]: services,
    [SERVICE_TYPES.SERVICES_GROUPS]: servicesGroups,
  };

  const modalButtonsId = 'modalButtons';

  const getServicesIdWithName = (ruleServices: IRuleService[]) => ruleServices.map((service) => {
    const positionName = getServiceName(service.id, servicesData[service.type]);

    return `${service.id} - ${positionName}`;
  });

  const removePosition = useCallback((positionToRemove: number) => {
    const newPositions = positions?.length === 1
      ? []
      : positions?.filter((posObj) => posObj.position !== positionToRemove);

    setFieldValue(name, newPositions);
  }, [name, positions, setFieldValue]);

  return (
    <>
      <div className={s.container}>
        <div className={s.title}>
          <h2>Позиция</h2>
          <SquareButton
            purpose="add"
            onClick={() => dispatch(openModal(modalButtonsId))}
          />
          {(errors && touched && typeof errors !== 'object') && <ErrorMessage>{errors}</ErrorMessage>}
        </div>
        {positions?.map((position, index) => {
          if (!position) return null;

          const modalId = `position${position.position}`;

          const addServices = (newServices: IRuleService[]) => {
            const concatedServices = [...position.items, ...newServices];

            setFieldValue(`${name}[${index}].items`, concatedServices);
          };

          const removeServiceByName = (nameToRemove: string) => {
            const idToRemove = Number(nameToRemove.split('-')[0]);
            const filteredItems = positions[index].items.filter(({ id }) => id !== idToRemove);

            setFieldValue(`${name}[${index}].items`, filteredItems);
          };

          const errorMessage = typeof touched === 'object' && touched[index]?.items
            && typeof errors === 'object' && errors[index]?.items as string;

          return (
            <Fragment key={position.position}>
              <div className={s.dropdowns}>
                <span className={s.label}>{`Позиция ${position.position + 1}:`}</span>
                <div>
                  <div>
                    <Dropdown data={getServicesIdWithName(position.items)} onClickDelete={removeServiceByName} />
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                  </div>
                  <SquareButton purpose="remove" onClick={() => removePosition(position.position)} />
                  <SquareButton purpose="add" onClick={() => dispatch(openModal(modalId))} />
                </div>
              </div>
              <ModalServicesSelects
                id={modalId}
                services={position.items}
                onSubmit={addServices}
              />
            </Fragment>
          );
        })}
      </div>
      <ModalButtons
        id={modalButtonsId}
        title="Выберите позиции"
        positions={positions}
        onSubmit={(selectedPositions) => {
          const concatedPositions = positions?.concat(selectedPositions);
          const sortedPositions = concatedPositions?.sort((a, b) => a.position - b.position);
          setFieldValue(name, sortedPositions);
        }}
      />
    </>
  );
};

export default PositionsSection;
