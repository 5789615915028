import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'store/slices/authSlice';

import s from './ProfileCard.module.css';

const ProfileCard = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: any) => state.auth);

  return (
    <div className={s.dropdownContainer}>
      <div className={s.info}>
        <p>{`${profile.name}`}</p>
        <p>{profile.mail}</p>
      </div>
      <hr />
      <button
        type="button"
        className={s.exitButton}
        onClick={() => dispatch(logout())}
      >
        Выйти
      </button>
    </div>
  );
};

export default ProfileCard;
