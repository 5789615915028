export enum NOTIFICATION_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

const TIME_TO_SHOW = 3000;

export { TIME_TO_SHOW };
