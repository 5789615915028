import React, { Fragment } from 'react';

import s from './RadioGroup.module.css';

interface IRadioGroup {
  title: string,
  children: React.ReactElement[],
}

const RadioGroup = ({ title, children }: IRadioGroup) => (
  <div>
    <div className={s.title}>{title}</div>
    <div className={s.radioGroup}>
      {children && children.map((radio: React.ReactElement) => (
        <Fragment key={radio.props.id}>{radio}</Fragment>
      ))}
    </div>
  </div>
);

export default RadioGroup;
