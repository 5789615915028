/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import { BiChevronDown, BiX, BiSearch } from 'react-icons/bi';
import ReactSelect, {
  components,
  ActionMeta,
  OnChangeValue,
  createFilter,
} from 'react-select';

import { IOption } from 'types/shared';
import CustomMenuList from './MenuList/MenuList';

import s from './Select.module.css';

const DropdownIndicator = ({ ...rest }: any) => (
  <components.DropdownIndicator {...rest}>
    <div className={s.iconContainer}><BiChevronDown /></div>
  </components.DropdownIndicator>
);

const SearchIndicator = ({ ...rest }: any) => (
  <components.DropdownIndicator {...rest}>
    <div className={s.iconContainer}><BiSearch /></div>
  </components.DropdownIndicator>
);

const MultiValueRemove = ({ ...rest }: any) => (
  <components.MultiValueRemove {...rest}>
    <div className={s.iconContainer}><BiX /></div>
  </components.MultiValueRemove>
);

const ClearIndicator = ({ ...rest }: any) => (
  <components.ClearIndicator {...rest}>
    <div className={s.iconContainer}><BiX /></div>
  </components.ClearIndicator>
);

const NoOptionsMessage = ({ ...rest }: any) => (
  <components.NoOptionsMessage {...rest}>
    Нет вариантов
  </components.NoOptionsMessage>
);

const MenuList = ({ ...rest }: any) => {
  const isChildrenExist = !!rest.children.length;
  const shortList = isChildrenExist && rest.children.slice(0, 30);

  return rest.children?.length > 10
    ? (<CustomMenuList {...rest}>{shortList}</CustomMenuList>)
    : (<components.MenuList {...rest}>{rest.children}</components.MenuList>);
};

const INDICATORS = {
  dropdown: DropdownIndicator,
  search: SearchIndicator,
};

interface ISelectProps {
  options?: IOption[],
  value?: string[] | string,
  onChange: (value?: string | string[]) => void,
  placeholder?: string,
  isClearable?: boolean,
  isSearchable?: boolean,
  isMulti?: boolean,
  variant?: 'outlined' | 'contained';
  indicator?: 'dropdown' | 'search',
  isDisabled?: boolean,
}

const Select = ({
  options,
  value,
  onChange,
  placeholder,
  isClearable,
  isSearchable,
  isMulti,
  variant = 'contained',
  indicator = 'dropdown',
  isDisabled = false,
}: ISelectProps) => {
  const handleChange = (newOptions: OnChangeValue<IOption, boolean>, action: ActionMeta<IOption>) => {
    if (newOptions) {
      if (isMulti) {
        const newOptionsValues = (newOptions as IOption[]).map((val) => val.value);

        const isEmptyArray = !newOptionsValues[0] && newOptionsValues.length <= 1;

        onChange(isEmptyArray ? undefined : newOptionsValues);
      } else {
        onChange((newOptions as IOption).value);
      }
    }

    if (action.action === 'clear') onChange(undefined);
  };

  const getValue = () => (
    value
      ? options?.filter((option) => value.indexOf(option.value) >= 0)
      : []
  );

  return (
    <ReactSelect
      options={options}
      value={getValue()}
      onChange={handleChange}
      placeholder={placeholder}
      className={s[variant]}
      isClearable={isClearable}
      isSearchable={isSearchable}
      components={{
        DropdownIndicator: INDICATORS[indicator],
        ClearIndicator,
        NoOptionsMessage,
        MultiValueRemove,
        MenuList,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: 'rgb(220, 220, 220, 1)',
          primary25: 'rgb(240, 240, 240, 1)',
          primary: 'rgb(225, 225, 225, 1)',
          danger: 'inherit',
          dangerLight: 'inherit',
          neutral10: 'rgb(240, 240, 240, 1)',
        },
      })}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      isDisabled={isDisabled}
      filterOption={createFilter({ ignoreAccents: false })}
    />
  );
};

export default Select;
